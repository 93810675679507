<template>
  <div>
    <h2>Genereer SEPA-export</h2>

    <div v-if="sepaExport">
      <a :href="sepaExport.url">Download {{ sepaExport.title }}</a>
    </div>

    <form v-else @submit.prevent="doExport()">
      <p>
        <label>
          Omschrijving<br>
          <input type="text" v-model="title" required />
        </label>
      </p>

      <p>
        <label>
          IBAN<br>
          <input type="text" v-model="iban" required />
        </label>
      </p>

      <p>
        <label>
          BIC<br>
          <input type="text" v-model="bic" required />
        </label>
      </p>

      <p>
        <label>
          Groep<br>
          <select v-model="groupId" required>
            <option :value="null">Selecteer een groep</option>
            <option :value="group.id" v-for="group in groups" :key="group.id">
              {{ group.name }}
            </option>
          </select>
        </label>
      </p>

      <template v-if="groupId">
        <h3>Tarieven</h3>

        <p v-for="role in roles" :key="role">
          <label>
            {{ role }}<br>
            <input type="text" @input="setTariff(role, $event)" required />
          </label>
        </p>

        <p>
          <button>Genereer SEPA-export</button>
        </p>
      </template>
    </form>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  props: {
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
    me: Object,
  },

  name: 'Finance',

  setup() {
    const sepaExport = ref(null);
    const groups = ref([]);
    const roles = ref([]);

    const variables = reactive({
      groupId: null,
      title: null,
      tariffs: '{}',
      iban: null,
      bic: null,
    });

    const Groups = `
      query groups {
        groups {
          id
          name
          roles
        }
      }
    `;

    const { data: groupsResult } = useQuery({
      query: Groups,
      cachePolicy: 'network-only',
    });

    const SepaExportQuery = `
      query SepaExport(
        $groupId: ID!,
        $tariffs: JSON!,
        $title: String!,
        $iban: String!,
        $bic: String!
      ) {
        sepaExport(
          groupId: $groupId,
          tariffs: $tariffs,
          title: $title,
          iban: $iban,
          bic: $bic
        )  {
          title
          url
        }
      }
    `;

    const { execute, unwatchVariables } = useQuery({
      query: SepaExportQuery,
      fetchOnMount: false,
      variables,
    });

    unwatchVariables();

    const doExport = () => {
      execute(variables)
        .then((result) => { sepaExport.value = result.data.sepaExport; });
    };

    const setTariff = (role, evt) => {
      const obj = JSON.parse(variables.tariffs);
      obj[role] = parseFloat(evt.target.value);
      variables.tariffs = JSON.stringify(obj);
    };

    watch(groupsResult, (result) => {
      groups.value = result.groups;
    });

    watch(variables, ({ groupId }) => {
      const group = groups.value.filter((g) => g.id === groupId)[0];
      if (group) { roles.value = group.roles; }
    });

    return {
      ...toRefs(variables),
      doExport,
      sepaExport,
      groups,
      roles,
      setTariff,
    };
  },
});
</script>
